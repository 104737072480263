import { Button } from '@qogita/ui'
import { PopupButton } from '@typeform/embed-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

import { NEW_SELLER_FORM_TYPEFORM_ID } from '#constants'
import {
  trackMarketingPageTypeformOpened,
  trackSignUpFormSubmitted,
} from '#src/utils/report/tracking'

export const RegisterButton = ({
  children,
  className,
  appearance,
  size,
  color,
}: {
  children: string
  className?: string
  appearance?: ComponentPropsWithoutRef<typeof Button>['appearance']
  size?: ComponentPropsWithoutRef<typeof Button>['size']
  color?: ComponentPropsWithoutRef<typeof Button>['color']
}) => {
  return (
    <PopupButton
      type="button"
      onSubmit={(event) => {
        trackSignUpFormSubmitted({
          typeformId: NEW_SELLER_FORM_TYPEFORM_ID,
          typeformResponseId: event.responseId,
        })
      }}
      // @ts-expect-error Button is not a valid html tag, but is a valid element
      as={({ onClick, ...popupButtonProps }) => (
        <Button
          {...popupButtonProps}
          appearance={appearance}
          size={size}
          color={color}
          onClick={() => {
            onClick?.()
            trackMarketingPageTypeformOpened({
              typeformButtonText: children,
              typeformID: NEW_SELLER_FORM_TYPEFORM_ID,
            })
          }}
        >
          {children}
        </Button>
      )}
      id={NEW_SELLER_FORM_TYPEFORM_ID}
      className={clsx('w-full sm:w-auto', className)}
    ></PopupButton>
  )
}
