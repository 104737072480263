import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import { render } from 'storyblok-rich-text-react-renderer'

import { RichtextStoryblok } from '#types/storyblok-component-types'

import { CmsErrorBoundary } from './CmsErrorBoundary'

const getSafeId = (children: string) => {
  return String(children)
    .replaceAll(/[^a-zA-Z0-9]/g, '-')
    .toLowerCase()
}

export const renderRichText = (
  richTextDocument: RichtextStoryblok | RichtextStoryblok[] | undefined,
) => {
  return render(richTextDocument, {
    nodeResolvers: {
      ordered_list: (children) => {
        return <ul className="ml-5 flex list-decimal flex-col">{children}</ul>
      },
      list_item: (children) => {
        /**
         * [&>p]:mb-2: The way the list renders, we have always a p after a li, this was a way to add the margin there without affecting other paragraphs.
         */
        return (
          <li className="text-pretty text-gray-700 [&>p]:mb-2">{children}</li>
        )
      },
      bullet_list: (children) => {
        return <ul className="ml-5 flex list-disc flex-col">{children}</ul>
      },
      paragraph: (children) => {
        return <p className="text-pretty text-gray-700">{children}</p>
      },
      heading: (children, { level }) => {
        if (level === 2) {
          return (
            <h2
              id={getSafeId(children as string)}
              className="text-2xl font-bold"
            >
              {children}
            </h2>
          )
        }

        return <h3 className="text-xl font-bold">{children}</h3>
      },
    },
  })
}

const renderTOC = (richTextDocuments: RichtextStoryblok[] | undefined) => {
  const headings = richTextDocuments?.filter(
    (item: RichtextStoryblok) => item.type === 'heading',
  )

  return render(
    {
      type: 'doc',
      content: headings,
    },
    {
      nodeResolvers: {
        paragraph: () => null,
        bullet_list: () => null,
        ordered_list: () => null,
        heading: (children, { level }) => {
          if (level === 2) {
            return (
              <a
                className="text-info-700"
                href={`#${getSafeId(children as string)}`}
              >
                {children}
              </a>
            )
          }
          return null
        },
      },
    },
  )
}

export const RichText = ({ blok }: { blok: RichtextStoryblok }) => {
  return (
    <CmsErrorBoundary>
      <div className="flex flex-col gap-4" {...storyblokEditable(blok)}>
        {renderRichText(blok.content)}
      </div>
    </CmsErrorBoundary>
  )
}

export const RichTextTOC = ({ blok }: { blok: RichtextStoryblok }) => {
  return (
    <CmsErrorBoundary>
      <p className="mb-3 gap-4 text-base font-bold">Sections</p>
      <div className="flex flex-col gap-2" {...storyblokEditable(blok)}>
        {renderTOC(blok.content)}
      </div>
    </CmsErrorBoundary>
  )
}
