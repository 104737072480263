import { useCanaryClient } from '@qogita/canary-client'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

import { getAuthQueries } from '#src/api/auth-queries'
import { REFRESH_SESSION_STALE_TIME } from '#src/constants/time'

export const useRefreshSession = () => {
  const canaryClient = useCanaryClient()
  const authQueries = getAuthQueries(canaryClient)
  const [renderCount, setRender] = useState(0)

  const query = useQuery({
    ...authQueries.refreshToken(),
    retry: false,
    staleTime: REFRESH_SESSION_STALE_TIME,
    refetchInterval: REFRESH_SESSION_STALE_TIME,
  })

  const queryClient = useQueryClient()

  /**
   * Reset the session data to invalid session, used after logout.
   */
  const invalidate = useCallback(() => {
    queryClient.setQueryData(
      authQueries.refreshToken().queryKey,
      () => undefined,
    )
    setRender((previous) => previous + 1) // force rerender because "setQueryData" doesn't trigger it
  }, [queryClient])

  return {
    renderCount, // keep to force rerender after reset
    invalidate,
    ...query,
  }
}
