import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import Image from 'next/image'
import React from 'react'

import { CenteredContent } from '#components/cms/CenteredContent'
import { CmsPanelErrorBoundary } from '#components/cms/CmsPanelErrorBoundary'
import { SellViaQogitaPanelStoryblok } from '#types/storyblok-component-types'

import { Anchor } from './Anchor'
import { renderRichText } from './RichText'
import { storyblokImageLoader } from './storyblokImageLoader'

function SellViaQogitaPanelComponent({
  blok,
}: {
  blok: SellViaQogitaPanelStoryblok
}) {
  const cta = blok.cta[0]
  return (
    <div {...storyblokEditable(blok)}>
      <CenteredContent>
        <div className="flex flex-col items-center gap-4 py-8 md:gap-8 md:py-12">
          <h2 className="text-balance text-4xl font-bold md:text-center">
            {blok.heading}
          </h2>
          <Image
            loader={storyblokImageLoader}
            src={blok.image.filename}
            alt={blok.image.alt ?? blok.title}
            height={1024}
            width={1024}
            className="object-contain"
          />
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8">
            {blok.items?.map((item) => {
              const ctaList = item.cta
              const cta = ctaList?.[0]
              return (
                <div
                  key={item._uid}
                  {...storyblokEditable(item)}
                  className="flex flex-col gap-2 md:flex-row md:gap-6"
                >
                  <div className="shrink-0 self-start md:p-1">
                    {item.icon?.map((icon) => (
                      <StoryblokComponent blok={icon} key={icon._uid} />
                    ))}
                  </div>
                  <div className="flex flex-col gap-4">
                    <h3 className="text-balance text-2xl font-bold">
                      {item.title}
                    </h3>
                    <div className="space-y-2 text-pretty text-gray-700">
                      {renderRichText(item.body)}
                    </div>
                    {cta ? (
                      <Anchor
                        blok={cta}
                        color="primary"
                        appearance="text"
                        size="small"
                        className="self-start"
                      >
                        <Anchor.Icon>
                          <ArrowRightIcon />
                        </Anchor.Icon>
                      </Anchor>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
          {cta ? (
            <CenteredContent>
              <Anchor
                blok={cta}
                color="primary"
                appearance="text"
                size="small"
                className="self-start"
              >
                <Anchor.Icon>
                  <ArrowRightIcon />
                </Anchor.Icon>
              </Anchor>
            </CenteredContent>
          ) : null}
        </div>
      </CenteredContent>
    </div>
  )
}

export const SellViaQogitaPanel = ({
  blok,
}: {
  blok: SellViaQogitaPanelStoryblok
}) => {
  return (
    <CmsPanelErrorBoundary>
      <SellViaQogitaPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
