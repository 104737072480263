import { storyblokEditable } from '@storyblok/react'
import Image from 'next/image'
import React from 'react'

import { CmsPanelErrorBoundary } from '#components/cms/CmsPanelErrorBoundary'
import { HeroStoryblok } from '#types/storyblok-component-types'

import { Anchor } from './Anchor'
import { storyblokImageLoader } from './storyblokImageLoader'

const HeroComponent = ({ blok }: { blok: HeroStoryblok }) => {
  const [cta] = blok.anonymousCta

  return (
    <div
      {...storyblokEditable(blok)}
      className="bg-marketing-primary-dark flex flex-col items-center overflow-hidden text-white"
    >
      <div className="relative flex w-full max-w-screen-lg flex-col items-center md:h-[578px] md:flex-row">
        <div className="relative h-72 w-full md:order-2 md:h-full">
          <Image
            loader={storyblokImageLoader}
            src={blok.image.filename}
            fill
            className="absolute object-contain object-[right_-4.5rem_top_0] md:overflow-visible md:object-cover md:object-left-top"
            alt=""
          />
        </div>
        <div className="flex flex-col px-4 pb-8 md:min-w-[36rem] md:max-w-xl md:items-start">
          <h1 className="mb-4 text-balance text-5xl font-bold">{blok.title}</h1>
          <p className="mb-4 text-pretty text-base">{blok.description}</p>
          <ul className="mb-6 flex justify-between sm:gap-10">
            {blok.stats?.map((stat) => (
              // storyblok renderer clones the children and renders them without a key, that's why we need to wrap it with a component that has a key, outise the component that has the storyblokEditable props
              <React.Fragment key={stat._uid}>
                <li
                  {...storyblokEditable(stat)}
                  className="border-l-2 border-l-white pl-3"
                >
                  <div className="text-2xl font-bold">{stat.value}</div>
                  <div className="text-sm font-bold">{stat.name}</div>
                </li>
              </React.Fragment>
            ))}
          </ul>
          <div>
            {cta ? (
              <Anchor
                blok={cta}
                color="primaryInverse"
                appearance="contained"
                size="medium"
                className="w-full md:w-auto"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
export const Hero = ({ blok }: { blok: HeroStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <HeroComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
