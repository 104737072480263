import { storyblokEditable } from '@storyblok/react'
import React from 'react'

import { CmsPanelErrorBoundary } from '#components/cms/CmsPanelErrorBoundary'
import { RegisterButton } from '#components/pages/index/panels/RegisterButton'
import { useUserContext } from '#contexts/User'
import { BannerPanelStoryblok } from '#types/storyblok-component-types'

import { Anchor } from './Anchor'

const BannerPanelComponent = ({ blok }: { blok: BannerPanelStoryblok }) => {
  const user = useUserContext()

  const title =
    user && blok.authenticatedTitle
      ? blok.authenticatedTitle
      : blok.anonymousTitle
  const description =
    user && blok.authenticatedDescription
      ? blok.authenticatedDescription
      : blok.anonymousDescription
  const [cta] =
    user && blok.authenticatedCta ? blok.authenticatedCta : blok.anonymousCta

  return (
    <div
      {...storyblokEditable(blok)}
      className="bg-marketing-primary-dark flex justify-center px-4 py-24"
    >
      <div className="flex max-w-5xl flex-col items-center justify-center gap-6 text-balance text-center text-white">
        <h2 className="text-4xl font-bold">{title}</h2>
        {description ? (
          <p className="max-w-2xl text-3xl text-gray-200">{description}</p>
        ) : null}
        {cta?.component === 'cta' ? (
          <Anchor
            blok={cta}
            className="w-full md:w-auto"
            color="primaryInverse"
            appearance="contained"
            size="medium"
          />
        ) : null}
        {cta?.component === 'cta_seller_registration' ? (
          <RegisterButton
            color="primaryInverse"
            appearance="contained"
            size="medium"
          >
            {cta.label}
          </RegisterButton>
        ) : null}
      </div>
    </div>
  )
}

export const BannerPanel = ({ blok }: { blok: BannerPanelStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <BannerPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
