import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import React from 'react'

import { CenteredContent } from '#components/cms/CenteredContent'
import { RegisterButton } from '#components/pages/index/panels/RegisterButton'
import { HeroPanelStoryblok } from '#types/storyblok-component-types'

export function HeroSellersPanel({ blok }: { blok: HeroPanelStoryblok }) {
  return (
    <div
      {...storyblokEditable(blok)}
      className="bg-marketing-primary-dark flex flex-col items-center text-white"
    >
      <CenteredContent>
        <div className="flex flex-col-reverse gap-4 py-12 md:grid md:grid-cols-2 md:items-center md:gap-6 md:py-14">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <h1 className="text-balance text-5xl font-bold">
                {blok.heading}
              </h1>
              <p className="text-pretty text-base text-gray-100">
                {blok.description}
              </p>
            </div>
            <div>
              <RegisterButton color="primaryInverse" appearance="contained">
                {blok.buttonCaption}
              </RegisterButton>
            </div>
          </div>
          <div>
            {blok.image.map((imageBlok) => (
              <StoryblokComponent blok={imageBlok} key={imageBlok?._uid} />
            ))}
          </div>
        </div>
      </CenteredContent>
    </div>
  )
}
