import { RefreshToken } from '@qogita/canary-types'
import { logError } from '@qogita/logging'
import { z } from 'zod'

import { queryClient } from '#contexts/QueryClient'
import { AUTH_QUERY_KEYS } from '#src/api/auth-queries'
import { REFRESH_SESSION_STALE_TIME, THIRTY_SECONDS } from '#src/constants/time'

const getTokenTimeToExpireInMs = (token: string) => {
  const tokenPayload = token.split('.')[1] || ''
  const decodedTokenPayload = atob(tokenPayload)
  const jsonPayload = JSON.parse(decodedTokenPayload) as { exp: number }
  const timeToExpire = jsonPayload.exp * 1000 - Date.now()
  return timeToExpire
}

/**
 * Grace period for access token should always be less than the refresh token stale time.
 * Because we expect to get a new access token before the old one expires.
 */
const ACCESS_TOKEN_GRACE_PERIOD = REFRESH_SESSION_STALE_TIME - THIRTY_SECONDS

export const getIsAccessTokenValid = (token: string) => {
  try {
    return getTokenTimeToExpireInMs(token) > ACCESS_TOKEN_GRACE_PERIOD
  } catch {
    return false
  }
}

export const getAccessToken = async () => {
  try {
    let token =
      queryClient.getQueryData<RefreshToken>(AUTH_QUERY_KEYS)?.accessToken

    if (!getIsAccessTokenValid(token || '')) {
      await queryClient.refetchQueries(
        {
          queryKey: AUTH_QUERY_KEYS,
          exact: true,
        },
        {
          cancelRefetch: false, // avoid duplicated requests
        },
      )
      token =
        queryClient.getQueryData<RefreshToken>(AUTH_QUERY_KEYS)?.accessToken
    }
    return token || ''
  } catch (error) {
    logError(error, { event: 'get_access_token_error' })
    return ''
  }
}

export const authResponseSchema = z.object({
  user: z.any(),
  accessToken: z.string(),
  signature: z.string(),
  accessExp: z.number(),
})
