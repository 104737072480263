import { useLogout } from '#contexts/Authentication'

export function SidebarLogoutButton() {
  const logout = useLogout()

  return (
    <button
      type="button"
      onClick={logout}
      className="flex h-20 flex-col justify-center px-6 text-left outline-none hover:bg-gray-200 focus:bg-gray-200"
    >
      <p className="pl-1 text-base font-semibold">Sign Out</p>
    </button>
  )
}
